import React, {
  useEffect, useRef, useState
} from 'react';

import amplitude from 'amplitude-js';
import classnames from 'classnames';
import { ErrorMessage, Field } from 'formik';

import { shuffle } from '../../helpers';

const Select = (data) => {
  const {
    attributes, errors, formRef, isActive, setValues, touched, values,
  } =
    data;
  const {
    className,
    dependency,
    innerClassName,
    isDependentField,
    isRequired,
    label,
    name,
    onMount,
    onSelectChange,
    onSelectChangeDeprecated,
    opts,
    randomizeOptions,
    showError,
  } = attributes;

  let [optKeys, setOptKeys] = useState(opts && Object.keys(opts));

  const inputEl = useRef(null);

  const onChange = (e) => {
    if (onSelectChange) {
      typeof onSelectChange === 'string'
        ? new Function('values', onSelectChange)({
          e,
          setValues,
          values,
        })
        : onSelectChange(e);
    }

    if (onSelectChangeDeprecated) {
      onSelectChangeDeprecated(e);
    }

    if (values) {
      setValues(Object.assign(values, { [name]: e.target.value }));
    }
  };

  const revealDependentField = (e, isOnLoad) => {
    if (dependency) {
      const operators = {
        '<': (a, b) => a < b,
        '<=': (a, b) => a <= b,
        '=': (a, b) => a === b,
        '>': (a, b) => a > b,
        '>=': (a, b) => a >= b,
      };
      var [
        _eslintIgnore_depName,
        operator,
        depValue,
      ] = dependency.split(',');

      var fieldValue = isOnLoad ? values[name] || '' : e.target.value;

      fieldValue = Number.parseInt(fieldValue) || fieldValue;
      depValue = Number.parseInt(depValue) || depValue;
      let isRevealed;

      if (typeof depValue === 'string' && depValue.includes('|')) {
        const depValues = depValue.split('|');

        isRevealed = depValues.find((value) =>
          operators[operator](fieldValue, value));
      } else {
        isRevealed = operators[operator](fieldValue, depValue);
      }

      const method = isRevealed ? 'add' : 'remove';
      const hiddenInput = inputEl.current?.nextSibling;

      if (hiddenInput) {
        const hiddenField = hiddenInput.closest('.-is-dependent-field');

        if (hiddenField) {
          hiddenField.classList[method]('-is-revealed');
        }
      }
    }
  };

  useEffect(() => {
    revealDependentField(null, true);
    optKeys =
      optKeys && randomizeOptions
        ? optKeys.slice(0, 1).concat(shuffle(optKeys.slice(1)))
        : optKeys;
    setOptKeys(optKeys);

    if (onMount) {
      new Function(
        'opts',
        'setOptKeys',
        'setValues',
        'values',
        'name',
        onMount
      )(opts, setOptKeys, setValues, values, name);
    }
  }, []);

  const Wrapper = label ? 'label' : 'div';

  const El = values ? Field : 'select';

  return (
    <Wrapper
      ref={inputEl}
      className={classnames({
        '-is-dependent-field': isDependentField,
        [className]: className,
        'field-2': !!label,
        select: true,
      })}
      onChange={onChange}
    >
      {label && <span className="field_label">{label}</span>}
      <El
        as="select"
        className={classnames({
          '-error': errors?.[name] && touched?.[name],
          '-touched': touched?.[name],
          [innerClassName]: innerClassName,
        })}
        name={name}
        onChange={(e) => {
          formRef?.current
            ?.querySelector(`[name=${name}]`)
            .classList.add('-touched');
          revealDependentField(e);
        }}
        onClick={() => {
          if (name === 'schedule_demo_timezone') {
            amplitude.getInstance().logEvent('Click - Timezone Selector ');
          }
        }}
        required={isRequired}
        tabIndex={!isActive || isDependentField ? -1 : undefined}
      >
        {optKeys &&
          optKeys.map((key, i) => (
            <option key={i} value={key}>
              {opts[key]}
            </option>
          ))}
      </El>

      {showError && (
        <ErrorMessage
          className="form_errorMessage"
          component="div"
          name={name}
        />
      )}
    </Wrapper>
  );
};

export default Select;
